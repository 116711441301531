<template>
  <div class="d-flex justify-end">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon small @click="editState = !editState">mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
    </v-menu>
    <edit-winner-dialog :state="editState" :winner="winner" @updateWalletDone="$emit('updateWalletDone', $event)" />
  </div>
</template>

<script>
export default {
  components: {
    EditWinnerDialog: () => import('../dialogs/edit-winner-dialog.vue')
  },
  props: {
    winner: Object
  },
  data() {
    return {
      editState: false
    }
  }
}
</script>
